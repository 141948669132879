@font-face {
  font-family: aerospace;
  src: url(./../res/fonts/Aerospace.ttf);
}
@font-face {
  font-family: mono-display;
  src: url(./../res/fonts/MajorMonoDisplay-Regular.ttf);
}
@font-face {
  font-family: bebas-neue;
  src: url(./../res/fonts/BebasNeue-Regular.ttf);
}
@media only screen and (max-width: 600px) {
  body {
    min-width: 500px;
  }
  h1 {
    font-size: 50px;
  }
  p {
    line-height: 1rem;
    padding-bottom: 30px;
  }
  h2 {
    margin-bottom: 2rem;
  }
}
@media only screen and (min-width: 600px) {
  body {
    min-width: 500px;
  }
  h1 {
    font-size: 50px;
  }
  p {
    line-height: 1rem;
    padding-bottom: 30px;
  }
  h2 {
    margin-bottom: 2rem;
  }
}
@media only screen and (min-width: 768px) {
  body {
    min-width: 800px;
  }
  h1 {
    font-size: 50px;
  }
  p {
    line-height: 1.5rem;
  }
  h2 {
    margin-bottom: 0.5rem;
  }
}
@media only screen and (min-width: 992px) {
  body {
    min-width: 1500px;
  }
  h1 {
    font-size: 50px;
  }
  p {
    line-height: 2rem;
  }
  h2 {
    margin-bottom: 2rem;
  }
}
body {
  background-color: #f9f9f9;
  font-family: mono-display;
  margin: 0;
  transition: all 0.2s;
  overflow-x: auto;
}

p {
  font-size: 0.8rem;
  font-weight: bold;
  color: white;
}

a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

h1 {
  color: white;
  font-family: aerospace;
}

h2 {
  font-family: mono-display;
  font-size: 3rem;
  font-weight: 200;
  color: white;
}

h3 {
  font-family: mono-display;
  font-size: 2rem;
  font-weight: 200;
  color: white;
}

img {
  -o-object-fit: contain;
     object-fit: contain;
}

#content {
  min-height: 90vh;
}

.collapsed {
  height: 0 !important;
}

@media only screen and (max-width: 600px) {
  .head {
    height: 50px;
    padding: 10px 0 0 20px;
  }
  #subotix-header {
    position: absolute;
    display: inline;
    right: calc(50% - 100px);
    margin: 0;
  }
  .dropdown {
    width: 100%;
  }
  .header-element {
    visibility: hidden;
    width: 0;
  }
}
@media only screen and (min-width: 600px) {
  .head {
    height: 50px;
    padding: 10px 0 0 20px;
  }
  #subotix-header {
    position: absolute;
    display: inline;
    right: calc(50% - 100px);
    margin: 0;
  }
  .dropdown {
    width: 100%;
  }
  .header-element {
    visibility: hidden;
    width: 0;
  }
}
@media only screen and (min-width: 768px) {
  .head {
    height: 50px;
    padding: 10px 0 0 20px;
  }
  #subotix-header {
    position: absolute;
    display: inline;
    right: calc(50% - 100px);
    margin: 0;
  }
  .dropdown {
    width: 30%;
  }
  .header-element {
    visibility: hidden;
    width: auto;
    opacity: 0;
  }
}
@media only screen and (min-width: 992px) {
  .head {
    height: 50px;
    padding: 10px 0 0 20px;
  }
  #subotix-header {
    position: absolute;
    display: inline;
    right: calc(50% - 130px);
    margin: 0;
  }
  .dropdown {
    width: 30%;
  }
  .header-element {
    visibility: visible;
    width: auto;
    font-family: mono-display;
    margin: 0 10px 15px 10px;
    opacity: 1;
  }
}
.header-element {
  font-size: 30px;
  transition: all 0s !important;
}

.head {
  position: relative;
  display: flex;
  justify-content: left;
  flex-direction: row;
  background-color: black;
  overflow: hidden;
  z-index: 1;
}

#header-menu {
  overflow: visible;
  flex: 1 1 0;
  display: flex;
  justify-content: left;
}

#header-title {
  overflow: visible;
  flex: 1 1 0;
  display: flex;
  justify-content: center;
}
#header-title h1 {
  margin: 0;
}

#header-socials {
  overflow: visible;
  flex: 1 1 0;
  display: flex;
  justify-content: right;
}

#subotix-header {
  position: absolute;
  display: inline;
  margin: 0;
}

.dropdown-element {
  color: white;
  font-family: mono-display;
  font-size: 30px;
  margin: 0 10px 0 20px;
  text-decoration: none;
}

button.dropdown-toggle {
  display: flex;
  flex-direction: column;
  background-color: black;
  width: 40px;
  border: none;
  padding: 0;
  margin: 0 0 15px 0;
  transition: all 0.3s;
}

div.dropdown-toggle {
  background-color: white;
  position: relative;
  width: 34px;
  height: 5px;
  left: 3px;
  border-radius: 3px;
}

button.dropdown-toggle:hover {
  background-color: white;
}
button.dropdown-toggle:hover .dropdown-toggle {
  background-color: black;
}

.dropdown {
  position: absolute;
  z-index: 1;
  background-color: black;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100px;
  transition: all 0.3s;
}

.header-element {
  color: white;
  text-decoration: none;
}

.glow-on-hover {
  border-radius: 2px;
  transition: all 0.5s !important;
}

.glow-on-hover:hover {
  color: black;
  background-color: white;
}

#footer {
  font-family: mono-display;
  display: flex;
  flex-direction: row;
  background-color: black;
  margin: 0;
  padding: 20px;
  font-size: xx-small;
}
#footer p {
  color: #777;
  margin: 0;
  padding: 0;
  line-height: 1.25rem;
  font-size: 1.7em;
}

#footer-links {
  padding-left: 30px;
  flex: 1 1 0;
}

.footer-icon {
  width: 40px;
}

#individual-info {
  flex: 2 1 0;
}

#misc-info {
  flex: 1 1 0;
}

.section {
  background-color: #f9f9f9;
  width: 100%;
  color: black;
  display: flex;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s;
}

.banner {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.infobar {
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  z-index: 1;
  transition: 0.3s;
}
.infobar h2 {
  margin-top: 20px;
  margin-bottom: 30px;
}
.infobar h3 {
  margin-bottom: 50px;
}

.infobar:hover {
  bottom: 0 !important;
  background-color: rgba(0, 0, 0, 0.9);
}

#hero-section {
  background-color: black;
  margin-bottom: 100px;
  display: grid;
  grid-template-columns: [c0] 1fr [c1] 1fr [c2] 1fr [c3] 1fr [c4] 1fr [c5];
  box-shadow: 0 5px 50px 50px black;
}

#hero-slide {
  grid-area: hero-slide;
}

#hero-title-div {
  grid-area: hero-title;
  overflow: visible;
  margin-bottom: 1em;
  padding: 2em;
  text-align: center;
}
#hero-title-div h2 {
  margin: 0;
}

#blank-paragraph-div {
  grid-area: hero-paragraph;
  color: white;
  font-size: small;
  transition: all 0.3s;
  padding: 2em;
  text-align: center;
}
#blank-paragraph-div p {
  line-height: 1.5em;
  font-size: 1.4em;
  font-weight: lighter;
}

#hero-links-div {
  grid-area: hero-links;
  align-self: center;
  background: none;
  display: flex;
  font-weight: bold;
  transition: all 0.3s;
  margin: auto;
}

.hero-link {
  background-color: white;
  position: relative;
  min-width: 150px;
  width: 30%;
  height: 50px;
  margin: auto;
  margin-bottom: 10px;
  transition: all 0.2s;
  font-size: large;
  line-height: 50px;
  text-align: center;
  color: black;
}

.hero-link:hover {
  background-color: #999;
}

#sawy-section {
  background-color: #f9f9f9;
  width: calc(100% - 50px);
  margin: 150px auto 150px auto;
  display: grid;
  justify-items: center;
  align-items: center;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

#sawy-title-div {
  grid-area: sawy-title;
  text-align: center;
  overflow: visible;
}

#sawy-paragraph-div {
  align-self: start;
  grid-area: sawy-paragraph;
  text-align: center;
  height: fi;
}

#sawy-link {
  background-color: black;
  color: white;
  padding: 15px;
}

.sawy-sec-thumbnail {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.sawy-sec-thumbnail img {
  width: 120%;
}

.sawy-sec-text {
  color: black;
}

#showcase {
  background-color: black;
  padding: 100px 0 100px 0;
  box-shadow: 0 5px 50px 50px black;
}

.showcase-panel {
  background-color: #f9f9f9;
  width: 70%;
  margin: 0 auto 100px auto;
  display: grid;
  align-items: center;
  justify-items: center;
}

.showcase-panel-image {
  grid-area: showcase-image;
  overflow: hidden;
  border-radius: 10px;
}
.showcase-panel-image img {
  width: 100%;
}

.showcase-panel-text {
  grid-area: showcase-text;
  text-align: center;
  justify-content: center;
}
.showcase-panel-text h2 {
  color: black;
  text-align: center;
  font-family: roboto;
  font-weight: bold;
  margin-bottom: 30px;
  padding: 10px;
}
.showcase-panel-text p {
  color: black;
  text-align: center;
  width: 60%;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  .infobar {
    padding-left: 5%;
  }
  .infobar h3 {
    font-size: 1.2rem;
  }
  .infobar p {
    width: 90%;
  }
  #hero-links-div {
    flex-direction: row;
    visibility: visible;
    width: 100%;
  }
  #blank-paragraph-div {
    display: none;
  }
  #hero-section {
    height: 900px;
    grid-template-rows: 500px 200px 200px;
    grid-template-areas: "hero-slide hero-slide hero-slide hero-slide hero-slide" "hero-title hero-title hero-title hero-title hero-title" "hero-links hero-links hero-links hero-links hero-links";
  }
  #sawy-section {
    height: 1100px;
    grid-template-rows: 250px 200px 200px 175px 175px;
    grid-template-areas: "image-1 image-1 image-1 image-1 image-1" "sawy-title sawy-title sawy-title sawy-title sawy-title" "sawy-paragraph sawy-paragraph sawy-paragraph sawy-paragraph sawy-paragraph" "image-2 image-2 image-2 image-4 image-4" "image-3 image-3 image-3 image-4 image-4";
  }
  .showcase-panel-image {
    width: 350px;
    height: 225px;
  }
  .showcase-panel {
    width: 80%;
    grid-template-columns: auto;
    grid-template-rows: 1fr 325px;
    grid-template-areas: "showcase-text" "showcase-image";
  }
}
@media only screen and (min-width: 600px) {
  .infobar {
    padding-left: 5%;
  }
  .infobar h3 {
    font-size: 1.2rem;
  }
  .infobar p {
    width: 90%;
  }
  #hero-links-div {
    flex-direction: row;
    visibility: visible;
    width: 600px;
  }
  #blank-paragraph-div {
    display: none;
  }
  #hero-section {
    height: 900px;
    grid-template-rows: 500px 200px 200px;
    grid-template-areas: "hero-slide hero-slide hero-slide hero-slide hero-slide" "hero-title hero-title hero-title hero-title hero-title" "hero-links hero-links hero-links hero-links hero-links";
  }
  #sawy-section {
    height: 1250px;
    grid-template-columns: 1fr 1fr 1fr 120px 120px;
    grid-template-rows: 300px 200px 200px 250px 250px;
    grid-template-areas: "image-1 image-1 image-1 image-1 image-1" "sawy-title sawy-title sawy-title sawy-title sawy-title" "sawy-paragraph sawy-paragraph sawy-paragraph sawy-paragraph sawy-paragraph" "image-2 image-2 image-2 image-4 image-4" "image-3 image-3 image-3 image-4 image-4";
  }
  .showcase-panel-image {
    width: 450px;
    height: 300px;
  }
  .showcase-panel {
    width: 80%;
    grid-template-columns: auto;
    grid-template-rows: 1fr 400px;
    grid-template-areas: "showcase-text" "showcase-image";
  }
}
@media only screen and (min-width: 768px) {
  .infobar {
    padding-left: 5%;
  }
  .infobar h3 {
    font-size: 1.2rem;
  }
  .infobar p {
    width: 60%;
  }
  #hero-links-div {
    flex-direction: column;
    visibility: visible;
    width: 180px;
  }
  #blank-paragraph-div {
    display: block;
  }
  #hero-section {
    height: 700px;
    grid-template-rows: 200px 200px 300px;
    grid-template-areas: "hero-title     hero-title     hero-title     hero-slide hero-slide" "hero-paragraph hero-paragraph hero-paragraph hero-slide hero-slide" "hero-links     hero-links     hero-links     hero-slide hero-slide";
  }
  #sawy-section {
    height: 1000px;
    grid-template-columns: 1fr 1fr 1fr 140px 140px;
    grid-template-rows: 250px 200px 200px 300px;
    grid-template-areas: "image-1 image-1 image-1 image-4 image-4" "sawy-title sawy-title sawy-title image-4 image-4" "sawy-paragraph sawy-paragraph sawy-paragraph image-4 image-4" "image-2 image-2 image-2 image-3 image-3";
  }
  .showcase-panel-image {
    width: 600px;
    height: 400px;
  }
  .showcase-panel {
    width: 80%;
    grid-template-columns: auto;
    grid-template-rows: 1fr 500px;
    grid-template-areas: "showcase-text" "showcase-image";
  }
}
@media only screen and (min-width: 992px) {
  .infobar {
    padding-left: 5%;
  }
  .infobar h3 {
    font-size: 1.2rem;
  }
  .infobar p {
    width: 60%;
  }
  #hero-links-div {
    flex-direction: row;
    visibility: visible;
    width: 600px;
  }
  #blank-paragraph-div {
    display: block;
  }
  #hero-section {
    height: 650px;
    grid-template-rows: 250px 250px 150px;
    grid-template-areas: "hero-title     hero-title     hero-slide hero-slide hero-slide" "hero-paragraph hero-paragraph hero-slide hero-slide hero-slide" "hero-links     hero-links     hero-slide hero-slide hero-slide";
  }
  #sawy-section {
    height: 650px;
    grid-template-columns: 300px 300px 1fr 1fr 300px;
    grid-template-rows: 300px 300px;
    grid-template-areas: "image-1 image-1 sawy-title sawy-title image-4" "image-2 image-3 sawy-paragraph sawy-paragraph image-4";
  }
  .showcase-panel-image {
    width: 600px;
    height: 400px;
  }
  .showcase-panel {
    min-width: 1000px;
    width: 70%;
    height: 500px;
    grid-template-columns: 700px 1fr;
    grid-template-rows: auto;
    grid-template-areas: "showcase-image showcase-text";
  }
}
@media only screen and (max-width: 600px) {
  #sawy-landing-content {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 20px 1fr 1fr 1fr 1fr;
    grid-template-areas: ". . . . . " ". sawy-title sawy-title sawy-title . " ". schem-link . apps-link . " ". sawy-video sawy-video sawy-video . " ". sawy-video sawy-video sawy-video . ";
  }
  #sawy-projects {
    grid-template-areas: "projects-header projects-header" "projects-horizontals projects-horizontals" "projects-verticals projects-verticals";
  }
  #two-column-container {
    flex-direction: column;
  }
  .sawy-sub-section {
    width: 75%;
    height: 400px;
  }
}
@media only screen and (min-width: 600px) {
  #sawy-title {
    font-size: 2em;
  }
  #sawy-landing-content {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 20px 1fr 1fr 1fr 1fr;
    grid-template-areas: ". . . . . " ". sawy-title sawy-title sawy-title . " ". schem-link . apps-link . " ". sawy-video sawy-video sawy-video . " ". sawy-video sawy-video sawy-video . ";
  }
  #sawy-projects {
    grid-template-areas: "projects-header projects-header" "projects-horizontals projects-horizontals" "projects-verticals projects-verticals";
  }
  #two-column-container {
    flex-direction: row;
  }
  .sawy-sub-section {
    width: 75%;
    height: 400px;
  }
}
@media only screen and (min-width: 768px) {
  #sawy-title {
    font-size: 3em;
  }
  #sawy-landing-content {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 20px 1fr 1fr 1fr 1fr;
    grid-template-areas: ". . . . . " ". sawy-title sawy-title sawy-title . " ". schem-link . apps-link . " ". sawy-video sawy-video sawy-video . " ". sawy-video sawy-video sawy-video . ";
  }
  #sawy-projects {
    grid-template-areas: "projects-horizontals projects-header" "projects-horizontals projects-verticals";
  }
  #two-column-container {
    flex-direction: row;
  }
  .sawy-sub-section {
    width: 25%;
    height: 400px;
  }
  .link-table {
    width: 70%;
  }
}
@media only screen and (min-width: 992px) {
  #sawy-landing-content {
    grid-template-columns: 16% 17% 17% 17% 17% 16%;
    grid-template-rows: 5% 30% 30% 30% 5%;
    grid-template-areas: ". . . . . ." ". sawy-title sawy-title sawy-video sawy-video ." ". apps-link schem-link sawy-video sawy-video ." ". . . . . ." ". . . . . .";
  }
  #sawy-projects {
    grid-template-areas: "projects-horizontals projects-header" "projects-horizontals projects-verticals";
  }
  #two-column-container {
    flex-direction: row;
  }
  .sawy-sub-section {
    width: 25%;
    height: 400px;
  }
  .link-table {
    width: 70%;
  }
}
.simon-game {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  padding: 50px;
  margin-top: 30px;
  color: white;
  border-radius: 20px;
}

.board {
  display: grid;
  grid-template-columns: repeat(3, 100px);
  grid-template-rows: repeat(3, 100px);
  grid-gap: 10px;
}

.direction-button {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  background-color: white;
  transition: background-color 0.3s, color 0.3s;
}
.direction-button span {
  color: black;
  transition: color 0.3s;
}
.direction-button.active {
  background-color: rgb(65, 65, 65);
}
.direction-button.active span {
  color: white;
}
.direction-button.up {
  grid-column: 2;
  grid-row: 1;
}
.direction-button.right {
  grid-column: 3;
  grid-row: 2;
}
.direction-button.down {
  grid-column: 2;
  grid-row: 3;
}
.direction-button.left {
  grid-column: 1;
  grid-row: 2;
}

.start-button {
  background-color: white;
  border: none;
  border-radius: none;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 2em;
  font-family: mono-display;
  transition: all 0.2s;
}

.start-button:hover {
  background-color: #999;
}

.message {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
}

#web-app-page {
  height: 1000px;
  width: 100%;
  margin: 100px 0 0 0;
  padding: 0;
}

#app-select-container {
  margin: 50px 20% 0 20%;
}

.app-select {
  background-color: black;
  color: white;
  width: 300px;
  height: 400px;
  margin: 10px;
  font-family: mono-display;
  font-size: 2em;
  border: none;
  transition: all 0.2s;
}

.app-select:hover {
  background-color: #999;
  color: black;
}

#sawy-apps-modal-bg {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
}

#sawy-apps-modal {
  background-color: white;
  justify-content: center;
  width: 1000px;
  height: 700px;
  margin: auto;
  padding: 20px;
  border-radius: 20px;
}

#close-app-button {
  background-color: white;
  color: black;
  border-radius: 5px;
  border: none;
  font-family: mono-display;
  font-weight: bold;
  font-size: 1.3em;
  line-height: 1.5em;
  transition: all 0.2S;
}

#close-app-button:hover {
  background-color: black;
  color: white;
}

#piano {
  background-color: black;
  min-width: 400px;
  min-height: 300px;
  width: 70%;
  height: 60%;
  padding: 30px;
  margin: 50px auto 0 auto;
  border-radius: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 5px;
}

#piano:focus {
  outline: none;
}

.piano-key {
  display: grid;
  grid-template-columns: 10px 1fr 10px;
  grid-template-rows: 1fr 50px;
  justify-items: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
}
.piano-key span {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  grid-column: 2/-2;
  grid-row: 2/-1;
  font-size: 15px;
  font-weight: bold;
}

.key-pressed {
  animation: piano-key-pressed 1s linear;
}

@keyframes piano-key-pressed {
  0% {
    background-color: white;
  }
  5% {
    background-color: #999;
  }
  10% {
    background-color: white;
  }
}
#sawy-projects {
  max-width: 1300px;
  margin: 100px auto 100px auto;
  padding: 10px;
  display: grid;
  gap: 20px;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
}

#sawy-projects-header {
  grid-area: projects-header;
  padding: 25px;
}

#sawy-projects-horizontals {
  background-color: black;
  grid-area: projects-horizontals;
  padding: 0 20px 0 20px;
  margin-top: 50px;
  height: -moz-fit-content;
  height: fit-content;
}

#sawy-projects-verticals {
  background-color: black;
  grid-area: projects-verticals;
  padding: 0 20px 0 20px;
  height: -moz-fit-content;
  height: fit-content;
}

.sawy-project-card {
  background-color: #f9f9f9;
  width: calc(100% - 40px);
  min-width: 280px;
  margin: 20px auto 20px auto;
  padding: 20px;
  border-radius: 10px;
}

.sawy-project-card-title {
  color: black;
  text-align: center;
  font-weight: bold;
}

.sawy-project-card-thumbnail {
  filter: drop-shadow(0 0 5px black);
  height: 150px;
}

.sawy-project-card-description {
  color: black;
  text-align: center;
  max-width: 60%;
  margin: auto;
  text-wrap: balance;
}

#sawy-landing {
  justify-content: center;
  background-color: black;
  max-width: 100%;
  height: 80vh;
  box-shadow: 0 5px 50px 50px black;
}

#sawy-landing-content {
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  gap: 30px;
  transition: all 0.2s;
}

#sawy-title {
  grid-area: sawy-title;
  background-color: black;
  color: white;
  width: calc(100% - 100px);
  padding: 50px;
  margin: auto;
  border-radius: 20px;
  text-align: center;
  transition: all 0.4s;
}

#sawy-title:hover {
  background-color: white;
  color: black;
}

#apps-link {
  grid-area: apps-link;
  margin: auto;
}

#schem-link {
  grid-area: schem-link;
  margin: auto;
}

#sawy-video {
  grid-area: sawy-video;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  border-radius: 20px;
}

.sawy-link-button {
  background-color: white;
  width: 180px;
  height: 70px;
  text-align: center;
  line-height: 70px;
  font-size: large;
  font-weight: bold;
  transition: all 0.2s;
}

.sawy-link-button:hover {
  background-color: #999;
}

#workshops {
  margin-bottom: 30px;
}

.back-to-sawy {
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 50px;
  margin-left: 10%;
  padding: 10px;
  font-size: 1.5em;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
}

.back-to-sawy:hover {
  color: white;
  background-color: black;
  transform: scale(1.05) translateX(-10px);
}

#download-section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#two-column-container {
  display: flex;
  padding: 50px 0 0 0;
}

.filmstrip {
  width: 0 !important;
}

.link-table {
  width: 80%;
  margin: 0px auto 20px auto !important;
}

.download-tab {
  display: flex;
  padding: 5px;
  border: solid;
  border-width: 1px;
}

.download-link {
  all: initial;
  width: 80%;
  overflow: hidden;
  cursor: pointer;
  text-decoration: underline;
  color: blue;
}

.download-link:focus {
  color: purple;
}

.download-link:visited {
  color: purple;
}

.slideshow {
  height: 100%;
  display: flex;
  background-color: black;
  margin: 0;
  overflow: hidden;
}

.slideshow-reel {
  height: 100%;
  white-space: nowrap;
  transition: ease 7s;
}

.slide {
  z-index: 1;
  background-color: white;
  display: inline-block;
  text-align: center;
  height: 100%;
  width: 100%;
}

.slide-image {
  background-color: white;
  visibility: visible;
  position: relative;
  z-index: 4;
  -o-object-fit: cover;
     object-fit: cover;
}

.accordian-fold {
  width: 80%;
  margin: 0 auto 0 auto;
  transition: all 0.3s;
}

.accordian-fold:hover {
  transform: scale(1.01);
}

.accordian-tab {
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: black;
  height: 50px;
  margin: 0;
  cursor: pointer;
}
.accordian-tab p {
  margin: 10px 0 0 0;
  font-size: medium;
  font-weight: lighter;
}

.accordian-tab::before {
  content: "";
  position: absolute;
  background-color: white;
  inset: 48px 0 0 0;
}

.accordian-content {
  background-color: black;
  overflow: hidden;
  transition: all 0.5s;
}

.accordian-label {
  font-size: 20px;
  margin: 10px 0 0 30px;
}

.accordian-icon {
  text-align: center;
  height: 45px !important;
  width: 50px !important;
  font-size: 50px !important;
  margin: 0 !important;
  padding: 5px 0 0 0 !important;
  transform: rotateZ(90deg);
  transition: all 0.2s;
}

.accordian-icon.collapsed {
  transform: rotateZ(0deg);
}/*# sourceMappingURL=App.css.map */