@media only screen and (max-width: 600px) {
    .head { height: 50px; padding: 10px 0 0 20px; }
    #subotix-header { position: absolute; display: inline; right: calc(50% - 100px); margin: 0 }
    .dropdown { width: 100% }
    .header-element { visibility: hidden; width: 0 }
}

@media only screen and (min-width: 600px) {
    .head { height: 50px; padding: 10px 0 0 20px; }
    #subotix-header { position: absolute; display: inline; right: calc(50% - 100px); margin: 0 }
    .dropdown { width: 100% }
    .header-element { visibility: hidden; width: 0 }
}

@media only screen and (min-width: 768px) {
    .head { height: 50px; padding: 10px 0 0 20px; }
    #subotix-header { position: absolute; display: inline; right: calc(50% - 100px); margin: 0 }
    .dropdown { width: 30% }
    .header-element { visibility: hidden; width: auto; opacity: 0 }
}

@media only screen and (min-width: 992px) {
    .head { height: 50px; padding: 10px 0 0 20px; }
    #subotix-header { position: absolute; display: inline; right: calc(50% - 130px); margin: 0 }
    .dropdown { width: 30% }
    .header-element { visibility: visible; width: auto; font-family: mono-display; margin: 0 10px 15px 10px; opacity: 1 }
}

.header-element {
    font-size: 30px;
    transition: all 0s !important;
}

.head {
    position: relative;
    display: flex;
    justify-content: left;
    flex-direction: row;
    background-color: black;
    overflow: hidden;
    z-index: 1;
}

#header-menu {
    overflow: visible;
    flex: 1 1 0;
    display: flex;
    justify-content: left;
}

#header-title {
    overflow: visible;
    flex: 1 1 0;
    display: flex;
    justify-content: center;
    h1 {
        margin: 0;
    }
}

#header-socials {
    overflow: visible;
    flex: 1 1 0;
    display: flex;
    justify-content: right;
}

#subotix-header {
    position: absolute;
    display: inline;
    margin: 0;
}

.dropdown-element {
    color: white;
    font-family: mono-display;
    font-size: 30px;
    margin: 0 10px 0 20px;
    text-decoration: none;
}

button.dropdown-toggle {
    display: flex;
    flex-direction: column;
    background-color: black;
    width: 40px;
    border: none;
    padding: 0;
    margin: 0 0 15px 0;
    transition: all 0.3s;
}

div.dropdown-toggle {
    background-color: white;
    position: relative;
    width: 34px;
    height: 5px;
    left: 3px;
    border-radius: 3px;
}

button.dropdown-toggle:hover {
    background-color: white;
    .dropdown-toggle {
        background-color: black;
    }
}

.dropdown {
    position: absolute;
    z-index: 1;
    background-color: black;
    display: flex;
    flex-direction: column;
    overflow: hidden;  
    height: 100px;
    transition: all 0.3s;
}

.header-element {
    color: white;
    text-decoration: none;
}

.glow-on-hover {
    border-radius: 2px;
    transition: all 0.5s !important;
}

.glow-on-hover:hover {
    color: black;
    background-color: white;
}