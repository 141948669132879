@font-face {
    font-family: aerospace;
    src: url(./../res/fonts/Aerospace.ttf);
}

@font-face {
    font-family: mono-display;
    src: url(./../res/fonts/MajorMonoDisplay-Regular.ttf);
}

@font-face {
    font-family: bebas-neue;
    src: url(./../res/fonts/BebasNeue-Regular.ttf);
}

$pallette-1: #222;
$pallette-2: #777;
$pallette-3: #f9f9f9;
$pallette-4: #999;

@media only screen and (max-width: 600px) {
    body {min-width: 500px;}
    h1 {font-size: 50px }
    p { line-height: 1rem; padding-bottom: 30px }
    h2 { margin-bottom: 2rem }
}

@media only screen and (min-width: 600px) {
    body {min-width: 500px;}
    h1 {font-size: 50px }
    p { line-height: 1rem; padding-bottom: 30px }
    h2 { margin-bottom: 2rem }
}

@media only screen and (min-width: 768px) {
    body {min-width: 800px;}
    h1 {font-size: 50px }
    p { line-height: 1.5rem }
    h2 { margin-bottom: 0.5rem }
}

@media only screen and (min-width: 992px) {
    body {min-width: 1500px;}
    h1 {font-size: 50px }
    p { line-height: 2rem }
    h2 { margin-bottom: 2rem }
}



body {
    background-color: $pallette-3;
    font-family: mono-display;
    margin: 0;
    transition: all 0.2s;
    overflow-x: auto;
}

p {
    font-size: 0.8rem;
    font-weight: bold;
    color: white;
}

a {
    text-decoration: none;
    color: black;
    cursor: pointer;
}

h1 {
    color: white;
    font-family: aerospace;
}

h2 {
    font-family: mono-display;
    font-size: 3rem;
    font-weight: 200;
    color: white;
}

h3 {
    font-family: mono-display;
    font-size: 2rem;
    font-weight: 200;
    color: white;
}

img {
    object-fit: contain;
}

#content {
    min-height: 90vh;
}

.collapsed {
    height: 0 !important;
}

@import 'Header.scss';
@import 'Footer.scss';
@import '../modules/Landing/styles/Landing.scss';
@import '../modules/SawyPage/styles/Sawy.scss';
@import 'Slideshow.scss';
@import 'Accordian.scss';