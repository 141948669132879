.accordian-fold {
    width: 80%;
    margin: 0 auto 0 auto;
    transition: all 0.3s;
}

.accordian-fold:hover {
    transform: scale(1.01);
}

.accordian-tab {
    position: relative;
    display: flex;
    flex-direction: row;
    background-color: black;
    height: 50px;
    margin: 0;
    cursor: pointer;
    p {
        margin: 10px 0 0 0;
        font-size: medium;
        font-weight: lighter;
    }
}

.accordian-tab::before {
    content: "";
    position: absolute;
    background-color: white;
    inset: 48px 0 0 0;
}

.accordian-content {
    background-color: black;
    overflow: hidden;
    transition: all 0.5s;
}

.accordian-label {
    font-size: 20px;
    margin: 10px 0 0 30px;
}

.accordian-icon {
    text-align: center;
    height: 45px !important;
    width: 50px !important;
    font-size: 50px !important;
    margin: 0 !important;
    padding: 5px 0 0 0 !important;
    transform: rotateZ(90deg);
    transition: all 0.2s;
}

.accordian-icon.collapsed {
    transform: rotateZ(0deg);
}