@import 'Simon.scss';

#web-app-page {
    height: 1000px;
    width: 100%;
    margin: 100px 0 0 0;
    padding: 0;
}

#app-select-container {
    margin: 50px 20% 0 20%;
}

.app-select {
    background-color: black;
    color: white;
    width: 300px;
    height: 400px;
    margin: 10px;

    font-family: mono-display;
    font-size: 2em;

    border: none;
    transition: all 0.2s;
}

.app-select:hover {
    background-color: $pallette-4;
    color: black;
}

#sawy-apps-modal-bg {
    background-color: rgba($color: black, $alpha: 0.6);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
}

#sawy-apps-modal {
    background-color: white;
    justify-content: center;
    width: 1000px;
    height: 700px;
    margin: auto;
    padding: 20px;
    border-radius: 20px;
}

#close-app-button {
    background-color: white;
    color: black;
    border-radius: 5px;
    border: none;

    font-family: mono-display;
    font-weight: bold;
    font-size: 1.3em;
    line-height: 1.5em;
    transition: all 0.2S;
}

#close-app-button:hover {
    background-color: black;
    color: white;
}

#piano {
    background-color: black;
    min-width: 400px;
    min-height: 300px;
    width: 70%;
    height: 60%;
    padding: 30px;
    margin: 50px auto 0 auto;
    border-radius: 30px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 5px;
}

#piano:focus {
    outline: none;
}

.piano-key {
    display: grid;
    grid-template-columns: 10px 1fr 10px;
    grid-template-rows: 1fr 50px;
    justify-items: center;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    span {
        user-select: none;
        grid-column: 2 / -2;
        grid-row: 2 / -1;
        font-size: 15px;
        font-weight: bold;
    }
}

.key-pressed {
    animation: piano-key-pressed 1s linear;
}

@keyframes piano-key-pressed {
    0% {background-color: white}
    5% {background-color: $pallette-4}
    10% {background-color: white}
}
