#sawy-projects {
    max-width: 1300px;
    margin: 100px auto 100px auto;
    padding: 10px;

    display: grid;
    gap: 20px;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    
}

#sawy-projects-header {
    grid-area: projects-header;
    padding: 25px;
}

#sawy-projects-horizontals {
    background-color: black;
    grid-area: projects-horizontals;

    padding: 0 20px 0 20px;
    margin-top: 50px;
    height: fit-content
}

#sawy-projects-verticals {
    background-color: black;
    grid-area: projects-verticals;

    padding: 0 20px 0 20px;
    height: fit-content
}

.sawy-project-card {
    background-color: $pallette-3;
    width: calc(100% - 40px);
    min-width: 280px;
    margin: 20px auto 20px auto;
    padding: 20px;
    border-radius: 10px;
}

.sawy-project-card-title {
    color: black;
    text-align: center;
    font-weight: bold;
}

.sawy-project-card-thumbnail {
    filter: drop-shadow(0 0 5px black);
    height: 150px;
}

.sawy-project-card-description {
    color: black;
    text-align: center;
    max-width: 60%;
    margin: auto;

    text-wrap: balance;
}