@import 'MultiMedia.scss';
@import 'WebAppPage.scss';
@import 'ProjectsSection.scss';

#sawy-landing {
    justify-content: center;
    background-color: black;
    max-width: 100%;
    height: 80vh;

    box-shadow: 0 5px 50px 50px black;
}

#sawy-landing-content {
    background-color: rgba(0, 0, 0, .9);
    position: absolute;
    width: 100%;
    height: 100%;

    display: grid;
    align-items: center;
    gap: 30px;

    transition: all 0.2s;
}

#sawy-title {
    grid-area: sawy-title;
    background-color: black;
    color: white;
    width: calc(100% - 100px);
    padding: 50px;
    margin: auto;

    border-radius: 20px;

    text-align: center;

    transition: all 0.4s;
}

#sawy-title:hover {
    background-color: white;
    color: black;
}

#apps-link {
    grid-area: apps-link;
    margin: auto;
}

#schem-link {
    grid-area: schem-link;
    margin: auto;
}

#sawy-video {
    grid-area: sawy-video;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    border-radius: 20px;

}

.sawy-link-button {
    background-color: white;
    width: 180px;
    height: 70px;

    text-align: center;
    line-height: 70px;
    font-size: large;
    font-weight: bold;

    transition: all 0.2s;
}

.sawy-link-button:hover {
    background-color: $pallette-4;
}

#workshops {
    margin-bottom: 30px;
}

.back-to-sawy {
    width: fit-content;
    margin-top: 50px;
    margin-left: 10%;
    padding: 10px;
    font-size: 1.5em;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s;
}

.back-to-sawy:hover {
    color: white;
    background-color: black;
    transform: scale(1.05) translateX(-10px);
}

#download-section {
    display: flex;
    flex-direction: column;
    width: 100%;
}

#two-column-container {
    display: flex;
    padding: 50px 0 0 0 
}

.filmstrip {
    width: 0 !important;
}


.link-table {
    width: 80%;
    margin: 0px auto 20px auto !important;
}

.download-tab {
    display: flex;
    padding: 5px;
    border: solid;
    border-width: 1px;
}

.download-link {
    all: initial;
    width: 80%;
    overflow: hidden;
    cursor: pointer;
    text-decoration: underline;
    color: blue;
}

.download-link:focus {
    color: purple;
}

.download-link:visited {
    color: purple;
}



