#showcase {
    background-color: black;
    padding: 100px 0 100px 0;

    box-shadow: 0 5px 50px 50px black;
}

.showcase-panel {
    background-color: $pallette-3;
    width: 70%;
    
    margin: 0 auto 100px auto;

    display: grid;
    align-items: center;
    justify-items: center;
}

.showcase-panel-image {
    grid-area: showcase-image;
    overflow: hidden;

    border-radius: 10px;

    img {
        width: 100%;
    }
}

.showcase-panel-text {
    grid-area: showcase-text;
    text-align: center;
    justify-content: center;
    h2{ color: black; text-align: center; font-family: roboto; font-weight: bold; margin-bottom: 30px; padding: 10px}
    p { color: black; text-align: center; width: 60%; margin: auto }
}