.section {
    background-color: $pallette-3;
    width: 100%;
    color: black;

    display: flex;
    position: relative;
    overflow: hidden;

    transition: transform 0.3s;
}

.banner {
    position: absolute;
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
}

.infobar {
    position: absolute;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    z-index: 1; 
    transition: 0.3s;
    h2 {
        margin-top: 20px;
        margin-bottom: 30px;
    }
    h3 {
        margin-bottom: 50px;
    }
}

.infobar:hover {
    bottom: 0 !important;
    background-color: rgba(0, 0, 0, 0.9);
}

@import 'Hero.scss';
@import 'SawyLanding.scss';
@import 'Showcase.scss';
@import 'MultiMedia.scss';








