#footer {
    font-family: mono-display;
    display: flex;
    flex-direction: row;
    background-color: black;
    margin: 0;
    padding: 20px;
    font-size: xx-small;
    p {
        color: $pallette-2;
        margin: 0;
        padding: 0;
        line-height: 1.25rem;
        font-size: 1.7em;
    }
}

#footer-links {
    padding-left: 30px;
    flex: 1 1 0;
}

.footer-icon {
    width: 40px;
}

#individual-info {
    flex: 2 1 0;
    
}

#misc-info {
    flex: 1 1 0;
}