.slideshow {
    height: 100%;
    display: flex;
    background-color: black;
    margin: 0;
    overflow: hidden;
}

.slideshow-reel {
    height: 100%;
    white-space: nowrap;
    transition: ease 7s;
}

.slide {
    z-index: 1;
    background-color: white;
    display: inline-block;
    text-align: center;
    height: 100%;
    width: 100%;
}

.slide-image {
    background-color: white;
    visibility: visible;
    position: relative;
    z-index: 4;
    object-fit: cover;
}