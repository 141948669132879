@media only screen and (max-width: 600px) {
    .infobar { padding-left: 5%; h3 { font-size: 1.2rem}; p { width: 90% } }

    #hero-links-div { flex-direction: row; visibility: visible; width: 100% }
    #blank-paragraph-div {display: none}
    #hero-section {
        height: 900px;
        grid-template-rows: 500px 200px 200px; 
        grid-template-areas: 
        "hero-slide hero-slide hero-slide hero-slide hero-slide"
        "hero-title hero-title hero-title hero-title hero-title"
        "hero-links hero-links hero-links hero-links hero-links";
    }

    #sawy-section {
        height: 1100px;
        grid-template-rows: 250px 200px 200px 175px 175px; 
        grid-template-areas: 
        "image-1 image-1 image-1 image-1 image-1"
        "sawy-title sawy-title sawy-title sawy-title sawy-title"
        "sawy-paragraph sawy-paragraph sawy-paragraph sawy-paragraph sawy-paragraph"
        "image-2 image-2 image-2 image-4 image-4"
        "image-3 image-3 image-3 image-4 image-4";
    }

    .showcase-panel-image { width: 350px; height: 225px }
    .showcase-panel {
        width: 80%;
        grid-template-columns: auto;
        grid-template-rows: 1fr 325px;
        grid-template-areas: "showcase-text" "showcase-image";
    }
}

@media only screen and (min-width: 600px) {
    .infobar { padding-left: 5%; h3 { font-size: 1.2rem}; p { width: 90% } }
    
    #hero-links-div { flex-direction: row; visibility: visible; width: 600px }
    #blank-paragraph-div {display: none}
    #hero-section {
        height: 900px;
        grid-template-rows: 500px 200px 200px; 
        grid-template-areas: 
        "hero-slide hero-slide hero-slide hero-slide hero-slide"
        "hero-title hero-title hero-title hero-title hero-title"
        "hero-links hero-links hero-links hero-links hero-links";
    }

    #sawy-section {
        height: 1250px;
        grid-template-columns: 1fr 1fr 1fr 120px 120px;
        grid-template-rows: 300px 200px 200px 250px 250px; 
        grid-template-areas: 
        "image-1 image-1 image-1 image-1 image-1"
        "sawy-title sawy-title sawy-title sawy-title sawy-title"
        "sawy-paragraph sawy-paragraph sawy-paragraph sawy-paragraph sawy-paragraph"
        "image-2 image-2 image-2 image-4 image-4"
        "image-3 image-3 image-3 image-4 image-4";
    }

    .showcase-panel-image { width: 450px; height: 300px }
    .showcase-panel {
        width: 80%;
        grid-template-columns: auto;
        grid-template-rows: 1fr 400px;
        grid-template-areas: "showcase-text" "showcase-image";
    }
}

@media only screen and (min-width: 768px) {
    .infobar { padding-left: 5%; h3 { font-size: 1.2rem}; p { width: 60% } }

    #hero-links-div { flex-direction: column; visibility: visible; width: 180px }
    #blank-paragraph-div {display: block }
    #hero-section {
        height: 700px;
        grid-template-rows: 200px 200px 300px; 
        grid-template-areas: 
        "hero-title     hero-title     hero-title     hero-slide hero-slide"
        "hero-paragraph hero-paragraph hero-paragraph hero-slide hero-slide"
        "hero-links     hero-links     hero-links     hero-slide hero-slide";
    }

    #sawy-section {
        height: 1000px;
        grid-template-columns: 1fr 1fr 1fr 140px 140px; 
        grid-template-rows: 250px 200px 200px 300px; 
        grid-template-areas: 
        "image-1 image-1 image-1 image-4 image-4"
        "sawy-title sawy-title sawy-title image-4 image-4"
        "sawy-paragraph sawy-paragraph sawy-paragraph image-4 image-4"
        "image-2 image-2 image-2 image-3 image-3";
    }

    .showcase-panel-image { width: 600px; height: 400px }
    .showcase-panel {
        width: 80%;
        grid-template-columns: auto;
        grid-template-rows: 1fr 500px;
        grid-template-areas: "showcase-text" "showcase-image";
    }
} 

@media only screen and (min-width: 992px) {
    .infobar { padding-left: 5%; h3 { font-size: 1.2rem}; p { width: 60% } }

    #hero-links-div { flex-direction: row; visibility: visible; width: 600px }
    #blank-paragraph-div {display: block }
    #hero-section {
        height: 650px;
        grid-template-rows: 250px 250px 150px; 
        grid-template-areas: 
        "hero-title     hero-title     hero-slide hero-slide hero-slide"
        "hero-paragraph hero-paragraph hero-slide hero-slide hero-slide"
        "hero-links     hero-links     hero-slide hero-slide hero-slide";
    }

    #sawy-section {
        height: 650px;
        grid-template-columns: 300px 300px 1fr 1fr 300px;
        grid-template-rows: 300px 300px; 
        grid-template-areas: 
        "image-1 image-1 sawy-title sawy-title image-4"
        "image-2 image-3 sawy-paragraph sawy-paragraph image-4";
    }

    .showcase-panel-image { width: 600px; height: 400px }
    .showcase-panel {
        min-width: 1000px;
        width: 70%;
        height: 500px;
        grid-template-columns: 700px 1fr;
        grid-template-rows: auto;
        grid-template-areas: "showcase-image showcase-text";
    }
}