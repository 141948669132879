#sawy-section {
    background-color: $pallette-3;
    width: calc(100% - 50px);
    margin: 150px auto 150px auto;

    display: grid;
    justify-items: center;
    align-items: center;
    gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

#sawy-title-div {
    grid-area: sawy-title;
    text-align: center;
    overflow: visible;
}

#sawy-paragraph-div {
    align-self: start;
    grid-area: sawy-paragraph;
    text-align: center;
    height: fi;
}

#sawy-link {
    background-color: black;
    color: white;
    padding: 15px;
}

.sawy-sec-thumbnail {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;

    img {
        width: 120%;
    }
}

.sawy-sec-text {
    color: black;
}