#hero-section {
    background-color: black;
    margin-bottom: 100px;

    display: grid;
    grid-template-columns: [c0] 1fr [c1] 1fr [c2] 1fr [c3] 1fr [c4] 1fr [c5]; 

    box-shadow: 0 5px 50px 50px black;
}

#hero-slide {
    grid-area: hero-slide;
}

#hero-title-div {
    grid-area: hero-title;
    overflow: visible;
    margin-bottom: 1em;
    padding: 2em;
    text-align: center;
    h2 {
        margin: 0;
    }
}

#blank-paragraph-div {
    grid-area: hero-paragraph;
    color: white;
    font-size: small;
    p { line-height: 1.5em; font-size: 1.4em; font-weight: lighter }
    transition: all 0.3s;
    padding: 2em;
    text-align: center;
}

#hero-links-div {
    grid-area: hero-links;
    align-self: center;
    background: none;
    display: flex;
    font-weight: bold;
    transition: all 0.3s;
    margin: auto;
}

.hero-link {
    background-color: white;
    position: relative;
    min-width: 150px;
    width: 30%;
    height: 50px;
    margin:  auto;
    margin-bottom: 10px;
    transition: all 0.2s;
    font-size: large;
    line-height: 50px;
    text-align: center;
    color: black;
}

.hero-link:hover {
    background-color: $pallette-4;
}