.simon-game {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    padding: 50px;
    margin-top: 30px;
    color: white;
    border-radius: 20px;
  }
  
  .board {
    display: grid;
    grid-template-columns: repeat(3, 100px);
    grid-template-rows: repeat(3, 100px);
    grid-gap: 10px;
  }
  
  .direction-button {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    background-color: white; // Set button color to white
    transition: background-color 0.3s, color 0.3s; // Transition for background and color
  
    span {
      color: black; // Set arrow color to black
      transition: color 0.3s; // Transition for arrow color
    }
  
    &.active {
      background-color: rgb(65, 65, 65); // Flash to gray on interaction
      span {
        color: white; // Change arrow color to white when active
      }
    }
  
    &.up {
      grid-column: 2;
      grid-row: 1;
    }
    &.right {
      grid-column: 3;
      grid-row: 2;
    }
    &.down {
      grid-column: 2;
      grid-row: 3;
    }
    &.left {
      grid-column: 1;
      grid-row: 2;
    }
  }
  
  .start-button {
    background-color: white;
    border: none;
    border-radius: none;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 2em;
    font-family: mono-display;
    transition: all 0.2s;
  }

  .start-button:hover {
    background-color: $pallette-4;
}
  
  .message {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
  }
  